import { PopupType } from "../types/popup";
import { BaseAction, ClientSiteData, RouteType } from "../types/site";
import { Vehicle } from "../types/vehicle";
import { showPopup } from "../hooks/usePopups";

export function createUrlFromPathAndQuery(sourceUrl: string, query: any): string {
  const paramsIndex = sourceUrl.indexOf("?");
  let urlParams: URLSearchParams;
  if (paramsIndex !== -1) {
    urlParams = new URLSearchParams(sourceUrl.substring(paramsIndex));
  } else {
    urlParams = new URLSearchParams();
  }
  const keys = Object.keys(query);
  keys.forEach((key) => {
    urlParams.append(key, query[key]);
  });
  if (paramsIndex !== -1) {
    const path = sourceUrl.substring(0, paramsIndex);
    return path + `?${urlParams.toString()}`;
  } else {
    return sourceUrl + `?${urlParams.toString()}`;
  }
}

export function handleButtonAction(buttonAction: BaseAction, data?: any) {
  if (buttonAction.path) {
    if (buttonAction.routeType === RouteType.POPUP) {
      let url: string;
      if (buttonAction.path.startsWith("/")) {
        // local path
        url = `${buttonAction.path}`;
      } else {
        url = buttonAction.path;
      }
      if (data) {
        url = createUrlFromPathAndQuery(url, data);
      }
      showPopup(PopupType.IFRAME, {
        url,
        width: buttonAction.width,
        height: buttonAction.height,
        allowScrolling: buttonAction.allowScrolling,
        allowFullScreen: buttonAction.allowFullScreen,
        mobileWidth: buttonAction.mobileWidth,
        mobileHeight: buttonAction.mobileHeight,
        closeButtonType: buttonAction.closeButtonType,
        maxWidth: buttonAction.maxWidth,
        maxHeight: buttonAction.maxHeight,
      });
    } else if (buttonAction.routeType === RouteType.NEW_WINDOW) {
      window.open(buttonAction.path, "_blank");
    } else {
      window.open(buttonAction.path, "_self");
    }
  }
}

export function getPhoneForVehicle(siteData: ClientSiteData, vehicle: Vehicle): string {
  let dealer = siteData.dealerInfos.find((item) => item.id === vehicle.dealer);
  if (!dealer) {
    dealer = siteData.dealerInfos[0];
  }
  return dealer.departments[0].phone;
}

export function getVehicleUrl(vehicle: Vehicle): string {
  return `/vehicle-details-page/${vehicle.id}`;
}

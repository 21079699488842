enum SiteTextSize {
  STANDARD = "16px",
  INCREASED = "20px",
}

export enum MonochromeValue {
  STANDARD = "unset",
  GRAYSCALE = "grayscale(1)",
}

const applyAccessibilityWidget = () => {
  if (typeof window !== "undefined" && window.localStorage.getItem("accessibility")) {
    let storageItem = JSON.parse(window.localStorage.getItem("accessibility") || "");
    let biggerText = storageItem.biggerText;
    let monochrome = storageItem.monochrome;
    let bigCursor = storageItem.bigCursor;
    let html = [window.parent.document.querySelector("html"), window.document.querySelector("html")];

    html.map((item) => {
      if (item) {
        item.style.fontSize = biggerText ? SiteTextSize.INCREASED : SiteTextSize.STANDARD;
        item.style.filter = monochrome ? MonochromeValue.GRAYSCALE : MonochromeValue.STANDARD;
        item.style.cursor = bigCursor ? `url("/images/big-cursor.svg"), auto` : "inherit";
      }
    });
  }
  return null;
};

export default applyAccessibilityWidget;

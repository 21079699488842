import { FC } from "react";
import cx from "classnames";
import styles from "./header-desktop.module.scss";
import { MenuItem, SubmenuItem, LogoPosition, MenuOrientation, MainMenu } from "../../../types/site";
import HeaderActions from "./header-actions";
import HeaderDesktopSubmenu from "./header-desktop-submenu";
import HeaderDesktopSubmenuAlternative from "./header-desktop-submenu-alternative";
import { RequestContext } from "../../../types/ui";
import { ModelCount } from "../../../types/ModelInfo";
import { Translations } from "../../../utils/translations";

interface IHeaderDesktop {
  context: RequestContext;
  menu: MainMenu;
  menuOrienation: MenuOrientation;
  logoUrl: string;
  logoPosition: LogoPosition;
  baseUrl: string;
  newModels: ModelCount[];
  translations: Translations;
}

export function isMenuActive(menuItem: MenuItem, activeMenu?: string): boolean {
  if (menuItem.path === activeMenu) {
    return true;
  }

  return menuItem.submenu && menuItem.submenu.some((item: SubmenuItem) => item.path === activeMenu);
}

const HeaderDesktop: FC<IHeaderDesktop> = async ({
  context,
  menu,
  menuOrienation,
  logoUrl,
  logoPosition,
  baseUrl,
  newModels,
  translations,
}) => {
  return (
    <div
      className={cx(styles.container, {
        [styles.centerMenu]: logoPosition === LogoPosition.CENTER,
      })}
    >
      <div className={styles.mainPanel}>
        {logoPosition !== LogoPosition.CENTER && (
          <a title={"logo"} className={styles.logo} href={"/"}>
            {logoUrl && <img src={logoUrl} alt="logo" />}
          </a>
        )}
        {menuOrienation === MenuOrientation.VERTICAL ? (
          <HeaderDesktopSubmenuAlternative
            headerMenu={menu.items || []}
            baseUrl={baseUrl}
            newModels={newModels}
            logoPosition={logoPosition}
            context={context}
          />
        ) : (
          <HeaderDesktopSubmenu
            headerMenu={menu.items || []}
            baseUrl={baseUrl}
            newModels={newModels}
            logoPosition={logoPosition}
            context={context}
          />
        )}
        <HeaderActions className={styles.actions} translations={translations} />
      </div>
    </div>
  );
};

export default HeaderDesktop;

export enum CloseButtonType {
  INTERNAL = "internal",
  EXTERNAL = "external",
  NONE = "none",
}

export interface PopupActionData {
  width?: number | string;
  height?: number | string;
  mobileWidth?: number | string;
  mobileHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  allowScrolling?: boolean;
  closeButtonType?: CloseButtonType;
  allowFullScreen?: boolean;
}

export enum PopupType {
  COOKIE = "cookie",
  IFRAME = "iframe",
  GLOBAL_SEARCH = "global_search",
  ACCESSIBILITY_WIDGET = "accessibility_widget",
}

export interface IFramePopupData extends PopupActionData {
  url: string;
  onMessageCallback?: (data: any) => void;
}

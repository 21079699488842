import { defaultLocale } from "../locales";
import { COOKIE_LOCALE_NAME } from "./constants";

export function getLanguage(locale?: string) {
  return locale || defaultLocale;
}

export function getDefaultLocale() {
  return defaultLocale;
}

export function setLocale(locale: string) {
  const expireCookie = new Date().getTime() + 365 * 24 * 60 * 60 * 1000;
  window.document.cookie = `${COOKIE_LOCALE_NAME}=${locale};path=/;expires=${new Date(expireCookie).toUTCString()}`;
}

export function changeLocale(locale: string) {
  setLocale(locale);
  window.location.reload();
}

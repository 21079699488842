export interface ILanguage {
  title: string;
  value: string;
}

export const languages: ILanguage[] = [
  {
    title: "English",
    value: "en",
  },
  {
    title: "Español",
    value: "es",
  },
];

export const SQUEEZE_POPUP_LS_NAME = "sqeeze_popup";
export const COOKIE_LOCALE_NAME = "NEXT_LOCALE";

"use client";

import { FC, useEffect } from "react";
import { setLocale } from "../../../utils/localeUtils";

interface ISetLocale {
  locale: string;
}

const SetLocale: FC<ISetLocale> = ({ locale }) => {
  useEffect(() => {
    setLocale(locale);
  }, [locale]);

  return null;
};

export default SetLocale;
